








































import { Component, Vue, Watch } from 'vue-property-decorator'
import PaginatedTable from '@bertazzoni/back/components/PaginatedTable.vue'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import UsersService from '@bertazzoni/common/src/services/UsersService'
import EntitiesService from '@bertazzoni/common/src/services/EntitiesService'
import { TableInfo } from '@bertazzoni/back/models/table.model'
import { UserType } from '@bertazzoni/common/src/models/user.model'
import ConfirmationModal from '@bertazzoni/back/components/ConfirmationModal.vue'
import ChooseEntityModal from '@bertazzoni/back/views/User/ChooseEntityModal.vue'
import { Entity } from '@bertazzoni/common/src/models/entity.model'
import { EntityType } from '@bertazzoni/common/src/models/entity.model'

@Component({
  components: {
    PaginatedTable,
    ConfirmationModal,
    ChooseEntityModal,
    Loader
  }
})
export default class EntityList extends Vue {
  private entityType = '' as EntityType
  private entityTypeCurrent = '' as EntityType
  private entityList: Array<Entity> = []
  private searchField = ''
  private tableInfo: TableInfo = new TableInfo()
  private data = []
  private loading = false
  private pageLoaded = false
  private showDisableModal = false
  private showEnableModal = false
  private showChooseEntityModal = false
  private currentUser = null
  private selectedEntity: { id: string; title: string }

  async created(): Promise<void> {
    this.entityType = this.$route.params.entityType as EntityType
    this.tableInfo.getCustomTable().searchAndCreate = true
    await this.getEntityList()
    await this.getUser()
    this.pageLoaded = true
  }
  async getEntityList(): Promise<void> {
    if (this.entityTypeCurrent != this.entityType) {
      this.tableInfo.paginInfo.offset = 0
      this.tableInfo.paginInfo.currentPage = 1
      this.entityTypeCurrent = this.entityType
    }
    this.loading = true
    const result = await EntitiesService.findAllPaginated({
      limit: this.tableInfo.paginInfo.limit,
      offset: this.tableInfo.paginInfo.offset,
      title: this.searchField,
      entityType: this.entityType
    })
    this.entityList = result.results
    this.buildEntityTable(result)
    this.loading = false
  }
  buildEntityTable(result) {
    this.tableInfo.getCustomTable().columns = ['title', 'enable']
    this.tableInfo.getCustomTable().displayTitle = true
    this.data = []
    this.entityList.map((entity: Entity) => {
      this.data.push({
        title: entity._id,
        objectType: entity.type,
        _id: entity._id,
        enable: entity.enable
      })
    })
    this.tableInfo.paginInfo.totalRow = result.total
  }
  async getUser(): Promise<void> {
    this.currentUser = await UsersService.findMe()
    this.tableInfo.userRight.userType = this.currentUser.type
    switch (this.currentUser.type) {
      case UserType.bertazzoniAdmin:
        this.tableInfo.userRight.crud = 'crud'
        break
      case UserType.countryAdmin:
        this.tableInfo.userRight.crud = 'r'
        break
      default:
        this.tableInfo.userRight.crud = ''
        break
    }
  }
  goEntityConsult(id: string) {
    this.$router.push(`/entity-consultation/${id}`)
  }
  openDisableModal(id: string, title: string) {
    this.selectedEntity = { id: id, title: title }
    this.showDisableModal = true
  }
  openEnableModal(id: string, title: string) {
    this.selectedEntity = { id: id, title: title }
    this.showEnableModal = true
  }
  async newLimit(limit: number) {
    this.tableInfo.paginInfo.limit = limit
    this.newPage(0)
  }
  async newPage(offset: number) {
    this.tableInfo.paginInfo.offset = offset
    await this.getEntityList()
  }
  async newSearch(searchField: string) {
    if (searchField !== this.searchField) {
      this.searchField = searchField
      this.newPage(0)
    }
  }
  async associateEntity(id: string) {
    this.$router.push(`/add-user/${this.entityType}/${id}`)
  }

  async disableEntity() {
    await EntitiesService.disableOne(this.selectedEntity.id)
    this.showDisableModal = false
    await this.getEntityList()
  }

  async enableEntity() {
    await EntitiesService.enableOne(this.selectedEntity.id)
    this.showEnableModal = false
    await this.getEntityList()
  }
  addEntity() {
    this.$router.push(`/add-entity/${this.entityType}`)
  }

  @Watch('$route.params.entityType')
  async newEntity(newValue: EntityType) {
    this.entityType = newValue
    await this.getEntityList()
  }
}
